<template>
  <div>
    <b-card title="Authors 🚀">
      <b-card-text>All the best for your new project.</b-card-text>
      <b-card-text>Please make sure to read our <b-link
        href="https://platypus.com"
        target="_blank"
      >
        Template Documentation
      </b-link> to understand where to go from here and how to use our template.</b-card-text>
    </b-card>

    <b-card title="Authors? 🔒">
      <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
      <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
    </b-card>
    <statistic-card-horizontal
      icon="CpuIcon"
      statistic="86%"
      statistic-title="CPU Usage"
      style="max-width:300px"
    />
    <b-avatar
      variant="danger"
      size="lg"
      src="https://placekitten.com/300/300"
      class="avatar-border-2"
    />
    <b-badge
      variant="light-primary"
    >Primary</b-badge>
    <b-badge
      variant="success"
    >Success</b-badge>
    <b-badge
      variant="danger"
    >Danger</b-badge>
    <b-card title="Want to integrate JWT? 🔒">
      <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
      <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
    </b-card>
    <b-badge
      pill
      variant="primary"
      class="badge-glow"
    >Primary</b-badge>
    <feather-icon
      icon="BellIcon"
      size="21"
      class="text-success"
      badge="4"
      badge-classes="badge-danger badge-glow"
    />
    <b-card title="Want to integrate JWT? 🔒">
      <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
      <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
    </b-card>
    <feather-icon
      class="text-danger mr-1"
      icon="TwitterIcon"
    />
    <b-card title="Want to integrate JWT? 🔒">
      <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
      <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
    </b-card>
    <b-card title="Want to integrate JWT? 🔒">
      <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
      <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
    </b-card>
    <statistic-card-with-line-chart
      icon="MonitorIcon"
      statistic="78.9k"
      statistic-title="Site Traffic"
      :chart-data="siteTraffic.series"
    />
  </div>
</template>

<script>
import {
  BCard, BCardText, BLink, BAvatar, BBadge,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BBadge,
    StatisticCardHorizontal,
    StatisticCardWithLineChart,
  },

  data() {
    return {
      // Line Charts
      siteTraffic: {},
    }
  },
  created() {
    // Site Traffic gained
    this.$http.get('/card/card-statistics/site-traffic')
      .then(response => {
        this.siteTraffic = response.data
        // console.log(response.data)
        this.siteTraffic = {
          series: [
            {
              name: 'Traffic Rate',
              data: [250, 100, 130, 150, 120, 222, 200, 125, 225, 200, 250],
            },
          ],
        }
      }, () => {
        // console.log(error)
        this.siteTraffic = {
          series: [
            {
              name: 'Errorrrrrrrr',
              data: [200, 250],
            },
          ],
        }
      })
    // this.siteTraffic = {
    //   series: [
    //     {
    //       name: 'Traffic Rate',
    //       data: [250, 100, 130, 150, 120, 222, 200, 125, 225, 200, 250],
    //     },
    //   ],
    // }
  },
}
</script>

<style>

</style>
